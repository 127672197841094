<template>
	<div class="my-page">
		<!-- 修改封面图片/个人头像 组件-->
		<div class="edit-cover-wrap" v-if="showTag === 2 || showTag === 3">
			<crop-image :showTag.sync="showTag" :showData.sync="showData" @back-2-userInfo="back2UserInfo"></crop-image>
		</div>
		<!-- 编辑封面 -->
		<div v-if="showTag === 1">
			<input type="file" accept="image/*" v-show="false" ref="imgInput" @change="handleImgChange" />
			<div class="cover">
				<img :src="userCover ? userCover : defaultCover" alt="" class="cover-photo" />
				<button class="edit-cover" @click="updateCover">编辑封面图片</button>
			</div>
			<!-- 个人信息 -->
			<div class="userinfo-wrap flex-layout" :class="{ collapsed: isCollapsed }">
				<button class="toggle" @click="toggleCollapse">
					<i :class="isCollapsed ? 'el-icon-arrow-down ' : 'el-icon-arrow-up'"></i>
					{{ isCollapsed ? "查看详细资料" : "收起详细资料" }}
				</button>
				<div class="user-avatar-wrap" @click="updateAvatar">
					<!-- <img :src="@/assets/images/user/userAvatar.png" alt="用户头像" class="user-avatar" /> -->
					<img :src="userInfo.avatar" alt="头像" class="user-avatar" />
				</div>
				<div class="userinfo">
					<div class="user-info-top">
						<div :style="{ 'margin-bottom': showSexIcon ? '18px' : '23px' }">
							<div class="user-name" v-if="!signatureEditing" @mouseenter="toggleEditBtn('name')" @mouseleave="toggleEditBtn('name')">
								<span class="content name-content" v-if="!nameEditing">{{ truncateName || "昵称" }}</span>
								<button class="user-edit-btn csp" v-if="nameEditBtn && !nameEditing" @click="startEdit('name')">修改</button>
								<div class="user-edit" v-if="nameEditing">
									<el-input class="edit-input" v-model="nameValue" placeholder="请填写名称" maxlength="32" :disabled="nameDisabled">
										<i slot="suffix">本月还可修改{{ nameEditTimes }}次</i></el-input
									>
									<el-button class="edit-submit-btn" type="primary" @click="submitEdit('name')">提交</el-button>
									<el-button class="edit-cancel-btn" @click="cancelEdit('name')">取消</el-button>
								</div>
							</div>
							<div
								class="user-signature"
								v-if="!nameEditing"
								@mouseenter="toggleEditBtn('signature')"
								@mouseleave="toggleEditBtn('signature')">
								<span class="content" v-if="!signatureEditing">{{ signatureValue || "未设置个人签名" }}</span>
								<button class="user-edit-btn csp" v-if="signatureEditBtn && !signatureEditing" @click="startEdit('signature')">
									修改
								</button>
								<div class="user-edit" v-if="signatureEditing">
									<el-input
										class="edit-input"
										v-model="signatureValue"
										maxlength="18"
										show-word-limit
										placeholder="请填写个性签名"></el-input>
									<el-button class="edit-submit-btn" type="primary" @click="submitEdit('signature')">提交</el-button>
									<el-button class="edit-cancel-btn" @click="cancelEdit('signature')">取消</el-button>
								</div>
							</div>
						</div>
						<div v-if="showSexIcon" class="user-sex-icon">
							<img v-if="genderValue == 1" src="@/assets/images/user/man.svg" alt="" />
							<img v-if="genderValue == 2" src="@/assets/images/user/woman.svg" alt="" />
						</div>
						<div class="user-reward">
							<span><b>IP</b>{{ userIp || "未知" }}</span>
							<span><b>成就等级</b>{{ userInfo.level }}</span>
							<span><b>积分</b>{{ userInfo.points || 0 }}</span>
						</div>
					</div>
					<!-- 所在行业 -->
					<div class="user-other user-industry" @mouseenter="toggleEditBtn('industry')" @mouseleave="toggleEditBtn('industry')">
						<span class="user-other-title">所在行业</span>
						<span v-if="!industryEditing">{{ industryValue || "未设置" }}</span>
						<button class="user-edit-btn csp" v-if="industryEditBtn && !industryEditing" @click="startEdit('industry')">修改</button>
						<div class="user-edit" v-if="industryEditing">
							<el-select v-model="industryValue" placeholder="未设置" :popper-append-to-body="false" style="width: 330px">
								<el-option v-for="item in industryOptions" :key="item.value" :label="item.label" :value="item.label"> </el-option>
							</el-select>
							<el-button class="edit-submit-btn" type="primary" @click="submitEdit('industry')">提交</el-button>
							<el-button class="edit-cancel-btn" @click="cancelEdit('industry')">取消</el-button>
						</div>
					</div>
					<!-- 现居地 -->
					<div class="user-other user-city" @mouseenter="toggleEditBtn('city')" @mouseleave="toggleEditBtn('city')">
						<span class="user-other-title">现居地</span>
						<span v-if="!cityEditing">{{ cityValue || "未设置" }}</span>
						<button class="user-edit-btn csp" v-if="cityEditBtn && !cityEditing" @click="startEdit('city')">修改</button>
						<div class="user-edit" v-if="cityEditing">
							<el-cascader
								v-model="cityValue"
								:options="cityOptions"
								popperClass="city-choose"
								:append-to-body="false"
								style="width: 250px"
								:props="{ value: 'label', emitPath: false }"
								@change="cityChange"></el-cascader>
							<el-button class="edit-submit-btn" type="primary" @click="submitEdit('city')">提交</el-button>
							<el-button class="edit-cancel-btn" @click="cancelEdit('city')">取消</el-button>
						</div>
					</div>
					<!-- 性别 -->
					<div class="user-other user-sex" @mouseenter="toggleEditBtn('gender')" @mouseleave="toggleEditBtn('gender')">
						<span class="user-other-title">性别</span>
						<span v-if="!genderEditing">{{ genderMap[genderValue] || "未设置" }}</span>
						<button class="user-edit-btn csp" v-if="genderEditBtn && !genderEditing" @click="startEdit('gender')">修改</button>
						<div class="user-edit" v-if="genderEditing">
							<el-select v-model="genderValue" placeholder="未设置" :popper-append-to-body="false" style="width: 180px">
								<el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
							<el-button class="edit-submit-btn" type="primary" @click="submitEdit('gender')">提交</el-button>
							<el-button class="edit-cancel-btn" @click="cancelEdit('gender')">取消</el-button>
						</div>
					</div>
					<!-- 手机号 -->
					<div class="user-other user-phone" @mouseenter="toggleEditBtn('phone')" @mouseleave="toggleEditBtn('phone')">
						<span class="user-other-title">手机号</span>
						<span>{{ formattedPhoneNumber }}</span>
						<i class="el-icon-success"></i>
						<button class="user-edit-btn csp" v-if="phoneEditBtn" @click="visibleEditPhone = true">修改</button>
					</div>
					<!-- 邮箱 -->
					<div class="user-other user-email" @mouseenter="toggleEditBtn('email')" @mouseleave="toggleEditBtn('email')">
						<span class="user-other-title">邮箱</span>
						<span>{{ emailValue }}</span>
						<i class="el-icon-success" v-if="emailVerified"></i>
						<button class="user-edit-btn user-check-btn csp" v-if="emailEditBtn && !emailVerified" @click="emailVerify()">验证</button>
						<button class="user-edit-btn user-email-btn csp" v-if="emailEditBtn" @click="editEmail">修改</button>
					</div>
					<!-- 个人简介 -->
					<div class="user-other" @mouseenter="toggleEditBtn('introduction')" @mouseleave="toggleEditBtn('introduction')">
						<span class="user-other-title">个人简介</span>
						<span class="content introduce-content" v-if="!introductionEditing">{{ introductionValue || "未填写" }}</span>
						<button class="user-edit-btn csp" v-if="introductionEditBtn && !introductionEditing" @click="startEdit('introduction')">
							修改
						</button>
						<div class="user-edit introduction" v-if="introductionEditing">
							<el-input
								type="textarea"
								maxlength="200"
								show-word-limit
								class="edit-textarea"
								resize="none"
								placeholder="填写个人简介"
								v-model="introductionValue"></el-input>
							<el-button class="edit-submit-btn" type="primary" @click="submitEdit('introduction')">提交</el-button>
							<el-button class="edit-cancel-btn" @click="cancelEdit('introduction')">取消</el-button>
						</div>
					</div>
				</div>
			</div>

			<!-- 具体信息 -->
			<collection></collection>

			<!-- 修改手机号弹窗 -->
			<el-dialog
				title="修改手机号码"
				:visible.sync="visibleEditPhone"
				:append-to-body="true"
				@opened="openDialog"
				@close="closePhoneDialog"
				:show-close="true"
				width="450px"
				:destroy-on-close="true"
				:close-on-click-modal="false"
				custom-class="editPhoneDialog">
				<el-form :model="editPhoneForm" :rules="phoneFormRules" ref="editPhoneForm">
					<el-form-item prop="phone">
						<el-input v-model="formattedPhoneNumber" disabled></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input class="code-input" v-model="editPhoneForm.code" id="code" placeholder="请输入验证码"> </el-input>
						<el-button class="send-code csp active" id="CaptchaId" :disabled="sendPhoneCodeBtn" @click="sendPhoneCode">
							{{ phoneCodeText }}
						</el-button>
					</el-form-item>
					<el-form-item prop="newPhone">
						<el-input v-model="editPhoneForm.newPhone" placeholder="新手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="newCode">
						<el-input class="code-input" v-model="editPhoneForm.newCode" id="newCode" placeholder="请输入验证码"> </el-input>
						<el-button class="send-code csp" id="CaptchaId" :disabled="sendPhoneNewCodeBtn" @click="sendNewPhoneCode">
							{{ newPhoneCodeText }}
						</el-button>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="phoneSubmit" :disabled="phoneSubmitBtn">确定</el-button>
					<button class="back csp" @click="closePhoneDialog">返回</button>
				</div>
			</el-dialog>

			<!-- 邮箱验证对话框 -->
			<el-dialog
				title="请登录邮箱进行验证"
				:visible.sync="visibleCheckEmail"
				:append-to-body="true"
				@opened="openDialog"
				@close="closeDialog"
				:show-close="true"
				width="450px"
				:destroy-on-close="true"
				:close-on-click-modal="false"
				custom-class="editPhoneDialog checkEmailDialog">
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="finishVerify" :loading="isLoginLoading">我已验证</el-button>
					<button class="back csp" @click="visibleCheckEmail = false">返回</button>
				</div>
			</el-dialog>

			<!-- 修改邮箱弹窗 -->
			<el-dialog
				title="修改邮箱地址"
				:visible.sync="visibleEditEmail"
				:append-to-body="true"
				@opened="openDialog"
				@close="closeEmailDialog"
				:show-close="true"
				width="450px"
				:destroy-on-close="true"
				:close-on-click-modal="false"
				custom-class="editPhoneDialog">
				<el-form :model="editEmailForm" :rules="emailFormRules" ref="editEmailForm">
					<el-form-item prop="phone">
						<el-input v-model="editEmailForm.phone" disabled></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input class="code-input" v-model="editEmailForm.code" id="code" placeholder="请输入验证码"> </el-input>
						<el-button class="send-code csp active" id="CaptchaId" :disabled="sendCodeBtn" @click="sendCode">
							{{ codeText }}
						</el-button>
					</el-form-item>
					<el-form-item prop="newEmail">
						<el-input v-model="editEmailForm.newEmail" id="newEmail" placeholder="新邮箱地址"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitEmail" :disabled="emailSubmitBtn" :loading="isLoginLoading">确定</el-button>
					<button class="back csp" @click="closeEmailDialog">返回</button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import collection from "./collection.vue";
import citysJson from "/static/ChinaCitys.json";
import cropImage from "./cropImage.vue";
export default {
	data() {
		// 验证验证码
		var checkCode = (rule, value, callback) => {
			var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
			if (phoneRegex.test(this.phoneNumber) && !value) {
				return callback(new Error("请填写验证码"));
			} else if (phoneRegex.test(this.phoneNumber)) {
				let params = {
					phone_num: this.phoneNumber,
					sms_code: value,
				};
				Api.User.verifySmsCode(params)
					.then((resData) => {
						console.log("手机验证码是否通过", resData.data.sms_correct);
						if (resData.data.sms_correct) {
							return callback();
						} else {
							return callback(new Error("短信验证码错误"));
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				return callback();
			}
		};
		// 验证新验证码
		var checkNewCode = (rule, value, callback) => {
			var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
			if (phoneRegex.test(this.editPhoneForm.newPhone) && !value) {
				return callback(new Error("请填写验证码"));
			} else if (phoneRegex.test(this.editPhoneForm.newPhone)) {
				let params = {
					phone_num: this.editPhoneForm.newPhone,
					sms_code: value,
				};
				Api.User.verifySmsCode(params)
					.then((resData) => {
						console.log("新---手机验证码是否通过", resData.data.sms_correct);
						if (resData.data.sms_correct) {
							if (!this.isPhoneFormValidating) {
								this.isPhoneFormValidating = true;
								console.log(this.isPhoneFormValidating);
								this.phoneFormValidate();
								// this.isEmailFormValidating = false;
							}
							return callback();
						} else {
							return callback(new Error("短信验证码错误"));
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				return callback();
			}
		};

		// 验证手机号
		var checkPhone = (rule, value, callback) => {
			var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
			if (!value) {
				return callback(new Error("请输入手机号"));
			} else if (!phoneRegex.test(value)) {
				this.sendCodeBtn = true;
				return callback(new Error("手机号格式异常，请检查并重新输入"));
			} else if (value && phoneRegex.test(value)) {
				let params = {
					email_or_phone_num: value,
				};
				Api.Login.getAccountExists(params)
					.then((resData) => {
						// console.log('exists手机号', resData.data.exists);
						if (resData.data.exists) {
							return callback(new Error("该手机号已被注册"));
						} else {
							this.sendPhoneNewCodeBtn = false;
							return callback();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				return callback();
			}
		};
		// 验证邮箱格式
		var checkEmail = (rule, value, callback) => {
			var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (!value) {
				return callback(new Error("请输入邮箱"));
			} else if (!emailRegex.test(value)) {
				return callback(new Error("请输入正确的邮箱格式"));
			} else if (value && emailRegex.test(value)) {
				let params = {
					email_or_phone_num: value,
				};
				Api.Login.getAccountExists(params)
					.then((resData) => {
						console.log("exists邮箱", resData.data.exists);
						if (resData.data.exists) {
							return callback(new Error("该邮箱已被注册"));
						} else {
							if (!this.isEmailFormValidating) {
								this.isEmailFormValidating = true;
								this.emailFormValidate();
								// this.isEmailFormValidating = false;
							}
							return callback();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				return callback();
			}
		};

		return {
			defaultCover: require("@/assets/images/user/cover.png"),
			isCollapsed: true,
			showSexIcon: true,
			userInfo: {},
			userIp: undefined,
			phoneNumber: undefined,
			// 名字编辑
			nameEditBtn: false,
			nameEditing: false,
			nameValue: undefined,
			nameEditTimes: undefined,
			nameDisable: false,
			// 个人签名编辑
			signatureEditBtn: false,
			signatureEditing: false,
			signatureValue: undefined,
			// 行业编辑
			industryEditBtn: false,
			industryEditing: false, // 控制编辑状态
			industryValue: undefined, // 输入框的值
			industryOptions: [
				{ value: "0", label: "气象服务" },
				{ value: "1", label: "科学研究与技术服务" },
				{ value: "2", label: "制造业" },
				{ value: "3", label: "信息技术和互联网" },
				{ value: "4", label: "金融业" },
				{ value: "5", label: "房地产与建筑业" },
				{ value: "6", label: "教育与培训" },
				{ value: "7", label: "医疗与健康" },
				{ value: "8", label: "文化娱乐与传媒" },
				{ value: "9", label: "零售与批发" },
				{ value: "10", label: "交通与物流" },
				{ value: "11", label: "能源与环境" },
				{ value: "12", label: "法律与咨询" },
				{ value: "13", label: "政府与公共事业" },
				{ value: "14", label: "农业与自然资源" },
				{ value: "15", label: "其他" },
			],
			// 现居地编辑
			cityEditBtn: false,
			cityEditing: false, // 控制编辑状态
			cityValue: undefined,
			cityOptions: citysJson,
			// 性别编辑
			genderEditBtn: false,
			genderEditing: false, // 控制编辑状态
			genderValue: undefined, // 输入框的值
			genderOptions: [
				{ value: 1, label: "男" },
				{ value: 2, label: "女" },
				{ value: 3, label: "保密" },
			],
			genderMap: { 1: "男", 2: "女", 3: "保密" },
			// 手机号编辑
			phoneEditBtn: false,
			phoneDialog: false, // 控制编辑状态

			// 修改手机号弹窗
			visibleEditPhone: false,
			editPhoneForm: {
				phone: undefined,
				code: undefined,
				newPhone: undefined,
				newCode: undefined,
			},
			currentEmail: undefined,
			sendPhoneCodeBtn: false,
			phoneCodeText: "发送验证码",
			sendPhoneNewCodeBtn: true,
			newPhoneCodeText: "发送验证码",
			phoneSubmitBtn: true,
			isPhoneFormValidating: false,
			phoneFormRules: {
				newPhone: [
					{
						required: true,
						trigger: "blur",
						validator: checkPhone,
					},
				],
				code: [
					{
						required: true,
						trigger: "blur",
						validator: checkCode,
					},
				],
				newCode: [
					{
						required: true,
						trigger: "blur",
						validator: checkNewCode,
					},
				],
			},
			// 邮箱编辑
			emailEditBtn: false,
			emailDialog: false, // 控制编辑状态
			emailValue: undefined,
			emailVerified: false,
			// 验证邮箱弹窗
			visibleCheckEmail: false,
			// 修改邮箱弹窗
			visibleEditEmail: false,
			editEmailForm: {
				phone: undefined,
				code: undefined,
				newEmail: undefined,
			},
			currentEmail: undefined,
			sendCodeBtn: false,
			codeText: "发送验证码",
			smsCode: undefined,
			emailSubmitBtn: true,
			isEmailFormValidating: false,
			emailFormRules: {
				code: [
					{
						required: true,
						trigger: "blur",
						validator: checkCode,
					},
				],
				newEmail: [
					{
						required: true,
						trigger: "blur",
						validator: checkEmail,
					},
				],
			},

			// 修改个人简介
			introductionEditBtn: false,
			introductionEditing: false,
			introductionValue: undefined,
			// 编辑封面
			userCover: "",
			showTag: 1, //
			imgClick: 0, //
			showData: {},
		};
	},
	components: {
		collection,
		cropImage,
	},
	computed: {
		formattedPhoneNumber() {
			if (this.phoneNumber && this.phoneNumber.length === 11) {
				return `${this.phoneNumber.slice(0, 3)}****${this.phoneNumber.slice(7)}`;
			}
			return this.phoneNumber;
		},
		truncateName() {
			if (this.nameValue && this.nameValue.length > 6) {
				return `${this.nameValue.slice(0, 6)}...`;
			}
			return this.nameValue;
		},
	},
	mounted() {
		// 获取封面
		this.getCover();
		this.getUserInfo();
		this.getNameChangeTimes();
		this.getIp();
	},
	methods: {
		// 查询用户当前IP
		getIp() {
			Api.User.getIp()
				.then((resData) => {
					if (resData.status == 200) {
						// console.log("当前IP", resData.data.ip);
						this.userIp = resData.data.location;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 修改封面
		updateCover(event) {
			console.log("点击修改封面", event);
			let token = Cookies.get("access_token");
			this.showData = {
				title: "主页封面图片设置",
				noCircle: true,
				noSquare: true,
				url: `${process.env.VUE_APP_API_URL}/user/cover-image`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				requestKey: "cover_image",
				width: 970,
				height: 240,
			};
			this.$refs.imgInput.click();
			this.imgClick = 2;
		},
		getCover() {
			Api.Login.getCover()
				.then((resData) => {
					const userCover = resData.data.cover_image;
					this.userCover = userCover;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAvatar() {
			Api.Login.getAvatar()
				.then((resData) => {
					const avatar = resData.data.avatar;
					this.userInfo.avatar = avatar;
					this.$store.dispatch("requestMiniInfo");
				})
				.catch((error) => {
					console.log(error);
				});
		},
		back2UserInfo(tag) {
			// 从修改封面返回
			if (tag === 2) {
				this.getCover();
			}
			if (tag === 3) {
				this.getAvatar();
			}
		},
		// 修改个人头像
		updateAvatar(event) {
			console.log("点击修改个人头像", event);
			let token = Cookies.get("access_token");
			this.showData = {
				title: "头像设置",
				noCircle: true,
				noSquare: true,
				url: `${process.env.VUE_APP_API_URL}/user/avatar`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				requestKey: "avatar",
				width: 110,
				height: 110,
			};
			this.$refs.imgInput.click();
			this.imgClick = 3;
		},
		// 重置fileinput内容
		resetFileInput() {},
		// 返回到用户信息页面
		returnUserInfo(tag) {
			// 从修改封面返回
			if (tag === 2) {
			}
			// 从修改个人头像返回
			if (tag == 3) {
			}
			this.showTag = 1;
		},
		// 图片input修改后触发
		handleImgChange(event) {
			const imgFile = event.target.files[0];
			this.showData["imgFile"] = imgFile;
			this.showTag = this.imgClick;
			this.imgClick = 0;
		},
		// 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		toggleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		toggleEditBtn(name) {
			this[`${name}EditBtn`] = !this[`${name}EditBtn`];
		},
		startEdit(name) {
			this[`${name}Editing`] = true;
			if (name == "name") {
				this.getNameChangeTimes();
			}
		},
		cityChange(value) {},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		// 提交编辑
		submitEdit(name) {
			// 处理提交逻辑
			let newName = this.capitalizeFirstLetter(name);
			let requestName = `change${newName}`;
			Api.User[requestName]({
				[name]: this[`${name}Value`],
			})
				.then((resData) => {
					// console.log('resData', resData)
					if (resData.status == 204) {
						this.$message.success("修改成功");
						this[`${name}Editing`] = false;
						this[`${name}EditBtn`] = false;
						this.getSingleInfo(name);
					}
				})
				.catch((error) => {
					this.$message.error("修改失败");
				});
		},
		// 请求修改后的数据
		getSingleInfo(name) {
			// console.log(name);
			let newName = this.capitalizeFirstLetter(name);
			let requestName = `get${newName}`;
			Api.User[requestName]()
				.then((resData) => {
					if (resData.status == 200) {
						// console.log("resData", resData.data[name]);
						if (name == "gender") {
							this.genderValue = resData.data.gender ? resData.data.gender : undefined;
							if (resData.data.gender == 3 || !resData.data.gender) {
								this.showSexIcon = false;
							} else {
								this.showSexIcon = true;
							}
							return;
						}
						if (name == "phone") {
							this.phoneNumber = resData.data.phone_num;
							return;
						}
						if (name == "name") {
							this.$store.dispatch("requestMiniInfo");
						}
						this[`${name}Value`] = resData.data[name];
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cancelEdit(name) {
			this[`${name}Editing`] = false;
			this.getSingleInfo(name);
			// this[`${name}Value`] = undefined;
		},
		// 发送手机验证码 （修改手机号）
		sendPhoneCode() {
			let that = this;
			// 成功后发送验证码
			this.sendPhoneCodeBtn = true;
			let params = {
				phone_num: that.phoneNumber,
			};
			Api.User.sendSmsCode(params)
				.then((resData) => {
					if (resData.status == 204) {
						this.$message.success("验证码发送成功");
						let countdownTimer = 60;
						let timer = null;
						this.phoneCodeText = countdownTimer + "s后重发";
						timer = setInterval(() => {
							countdownTimer--;
							this.phoneCodeText = countdownTimer + "s后重发";
							if (countdownTimer <= 0) {
								clearInterval(timer);
								this.phoneCodeText = "发送验证码";
								this.sendPhoneCodeBtn = false;
							}
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					this.sendCodeBtn = false;
				});
		},
		// 发送手机验证码 (新手机号)
		sendNewPhoneCode() {
			let that = this;
			// 成功后发送验证码
			this.sendPhoneNewCodeBtn = true;
			let params = {
				phone_num: that.editPhoneForm.newPhone,
			};
			Api.User.sendSmsCode(params)
				.then((resData) => {
					if (resData.status == 204) {
						this.$message.success("验证码发送成功");
						let countdownTimer = 60;
						let timer = null;
						this.newPhoneCodeText = countdownTimer + "s后重发";
						timer = setInterval(() => {
							countdownTimer--;
							this.newPhoneCodeText = countdownTimer + "s后重发";
							if (countdownTimer <= 0) {
								clearInterval(timer);
								this.newPhoneCodeText = "发送验证码";
								this.sendPhoneNewCodeBtn = false;
							}
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					this.sendCodeBtn = false;
				});
		},
		// 邮箱表单校验
		phoneFormValidate() {
			this.$refs.editPhoneForm.validate((valid) => {
				if (valid) {
					this.phoneSubmitBtn = false;
				} else {
					this.phoneSubmitBtn = true;
				}
			});
		},
		phoneSubmit() {
			this.isPhoneFormValidating = false;
			Api.User.changePhone({
				phone_num: this.editPhoneForm.newPhone,
				sms_code: this.editPhoneForm.newCode,
			})
				.then((resData) => {
					if (resData.status == 204) {
						this.visibleEditPhone = false;
						this.$message.success("修改手机号成功");
						this.getSingleInfo("phone");
					}
				})
				.catch((error) => {
					console.log("修改失败", error);
					this.$message.error("修改手机号失败");
				});
		},

		// 发送验证码 (修改邮箱)
		sendCode() {
			let that = this;
			this.sendCodeBtn = true;
			// 成功后发送验证码
			let params = {
				phone_num: that.phoneNumber,
			};
			Api.User.sendSmsCode(params)
				.then((resData) => {
					if (resData.status == 204) {
						this.$message.success("验证码发送成功");
						let countdownTimer = 60;
						let timer = null;
						this.codeText = countdownTimer + "s后重发";
						timer = setInterval(() => {
							countdownTimer--;
							this.codeText = countdownTimer + "s后重发";
							if (countdownTimer <= 0) {
								clearInterval(timer);
								this.codeText = "发送验证码";
								this.sendCodeBtn = false;
							}
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					this.sendCodeBtn = false;
				});
		},
		// 获取个人信息
		getUserInfo() {
			Api.User.userInfo()
				.then((resData) => {
					console.log("个人信息", resData.data);
					this.userInfo = resData.data;
					this.nameValue = resData.data.name;
					this.phoneNumber = resData.data.phone_num;
					if (!resData.data.gender || resData.data.gender == 3) {
						this.showSexIcon = false;
					} else {
						this.showSexIcon = true;
					}
					this.genderValue = resData.data.gender ? resData.data.gender : undefined;
					this.industryValue = resData.data.industry;
					this.cityValue = resData.data.city;
					this.introductionValue = resData.data.introduction;
					this.signatureValue = resData.data.signature;
					this.emailValue = resData.data.email;
					this.getEmailStatus();
				})
				.catch((error) => {});
		},
		// 查询名字可修改次数
		getNameChangeTimes() {
			Api.User.getNameChangeTimes({
				email: this.emailValue,
			})
				.then((resData) => {
					if (resData.status == 200) {
						this.nameEditTimes = resData.data.times;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 查询邮箱是否验证通过
		getEmailStatus() {
			Api.User.getEmailStatus({
				email: this.currentEmail || this.emailValue,
			})
				.then((resData) => {
					if (resData.status == 200) {
						this.emailVerified = resData.data.state;
						this.getSingleInfo("email");
					}
				})
				.catch((error) => {
					this.getSingleInfo("email");
					console.log(error);
				});
		},

		// 发送邮箱验证邮件
		emailVerify(email) {
			this.visibleCheckEmail = true;
			console.log("邮箱", email);
			Api.User.sendEmail({
				email: email || this.emailValue,
			})
				.then((resData) => {
					if (resData.status == 204) {
						this.currentEmail = email || this.emailValue;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 我已验证
		finishVerify() {
			this.visibleCheckEmail = false;
			this.getEmailStatus();
		},
		// 修改邮箱
		editEmail() {
			this.visibleEditEmail = true;
			this.$set(this.editEmailForm, "phone", this.formattedPhoneNumber);
		},
		// 邮箱表单校验
		emailFormValidate() {
			this.$refs.editEmailForm.validate((valid) => {
				if (valid) {
					this.emailSubmitBtn = false;
				} else {
					this.emailSubmitBtn = true;
				}
			});
		},
		// 提交修改邮箱表单
		submitEmail() {
			this.isEmailFormValidating = false;

			Api.User.changeEmail({
				email: this.editEmailForm.newEmail,
				sms_code: this.editEmailForm.code,
			})
				.then((resData) => {
					if (resData.status == 204) {
						this.currentEmail = this.editEmailForm.newEmail;
						this.visibleEditEmail = false;
						this.visibleCheckEmail = true;
						// this.emailVerify(this.editEmailForm.newEmail);
					}
				})
				.catch((error) => {
					console.log("修改失败", error);
				});
		},
		// 关闭邮箱弹窗
		closeEmailDialog() {
			this.visibleEditEmail = false;
			this.resetForm("editEmailForm");
		},
		// 关闭手机修改弹窗
		closePhoneDialog() {
			this.visibleEditPhone = false;
			this.resetForm("editPhoneForm");
		},
	},
};
</script>

<style lang="less" scoped>
.my-page {
	padding-right: 8px;
	height: 100%;
	overflow-y: scroll;
	width: 100%;

	/*修改滚动条样式*/
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 2px;
	}

	&::-webkit-scrollbar-button {
		height: 0px;
	}

	&::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 4px;
	}

	// 封面
	.cover {
		margin-bottom: 12px;
		width: 100%;
		height: 240px;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
		flex-shrink: 0;

		.edit-cover {
			position: absolute;
			top: 28px;
			right: 40px;
			width: 120px;
			line-height: 36px;
			padding: 0 10px;
			text-align: center;
			border-radius: 4px;
			background: rgba(153, 153, 153, 0.4);
			color: #fff;

			&:hover {
				background: rgba(153, 153, 153, 0.85);
			}
		}
	}

	// 个人信息
	.userinfo-wrap {
		margin-bottom: 12px;
		border-radius: 10px;
		width: 100%;
		max-height: 800px;
		background: #fff;
		overflow: hidden;
		transition: max-height 0.8s ease;
		position: relative;
		padding: 45px 48px 45px 58px;
		box-sizing: border-box;

		&.collapsed {
			// height: 200px;
			min-height: 200px;
			max-height: 200px;
		}

		.toggle {
			position: absolute;
			top: 155px;
			right: 48px;
			color: #7c7c7c;

			&:hover {
				color: #01aded;
			}
		}

		.user-avatar-wrap {
			width: 110px;
			height: 110px;
			border-radius: 50%;
			margin-right: 42px;
			overflow: hidden;
			position: relative;
			z-index: 1;
			cursor: pointer;

			.user-avatar {
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.15);
				text-align: center;
				line-height: 110px;
			}

			&:hover {
				&::before {
					display: block;
					content: "修改头像";
					position: absolute;
					top: 0;
					left: 0;
					width: 110px;
					height: 110px;
					z-index: 2;
					background-color: rgba(0, 0, 0, 0.35);
					color: #fff;
					font-size: 16px;
					text-align: center;
					line-height: 110px;
				}
			}
		}

		.userinfo {
			color: #333;
			width: 70%;
		}

		.user-info-top {
			padding-top: 6px;
			padding-bottom: 3px;
			height: 101px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			/deep/ .edit-input {
				width: 330px;

				.el-input__inner {
					border-radius: 10px;
				}

				.el-input__inner:focus {
					border: 1px solid #01aded;
				}

				.el-input .el-select__caret {
					font-size: 12px;
				}
			}

			.user-name {
				display: inline-block;
				padding-right: 34px;

				.content {
					display: inline-block;
					font-size: 20px;
					font-weight: 600;
					// max-width: 140px;
					// // width: 120px;
					// overflow: hidden;
					// word-wrap: normal;
					// white-space: nowrap;
					// text-overflow: ellipsis;
				}

				.user-edit-btn {
					margin-left: 20px;
				}

				.edit-input {
					width: 330px;
					position: relative;

					/deep/.el-input__inner {
						padding-right: 130px;
					}
				}

				/deep/.el-input__suffix-inner {
					color: #9f9f9f;
					line-height: 40px;
					padding-right: 10px;
				}
			}
			.user-signature {
				display: inline-block;
				padding: 2px 50px 2px 0;
			}

			.user-sex-icon {
				margin-bottom: 18px;
			}

			.user-reward {
				position: relative;

				span {
					margin-right: 50px;

					&:first-child {
						font-weight: 600;
					}

					b {
						margin-right: 10px;
					}
				}
			}
		}

		.user-other {
			margin-top: 42px;

			.user-other-title {
				display: inline-block;
				font-weight: 600;
				width: 56px;
				margin-right: 45px;
			}

			.el-icon-success {
				margin-left: 10px;
				margin-right: 28px;
				font-size: 14px;
				color: #27d68f;
			}

			// .el-icon-warning {
			// 	margin-left: 10px;
			// 	font-size: 14px;
			// 	color: #ff4545;

			// 	&::after {
			// 		content: "验证失败";
			// 		display: inline-block;
			// 		margin: 0px 8px 0 5px;
			// 	}
			// }
			.introduce-content {
				display: inline-block;
				width: 430px;
				vertical-align: top;
				white-space: normal;
				word-wrap: break-word;
				word-break: break-all;
			}

			.introduction {
				vertical-align: top;
				.edit-textarea {
					width: 300px;
					vertical-align: middle;
					height: 251px;
				}
				/deep/ .el-textarea__inner {
					height: 251px;

					&:focus {
						border-color: #01aded;
					}
				}
				.edit-submit-btn,
				.edit-cancel-btn {
					vertical-align: bottom;
				}
			}
		}

		.user-edit-btn {
			margin-left: 12px;
			color: #01aded;
			font-weight: 600;

			&:hover {
				color: rgba(1, 173, 237, 0.65);
			}

			&.user-check-btn {
				&::after {
					content: " ";
					width: 1px;
					height: 12px;
					display: inline-block;
					background-color: #9f9f9f;
					margin-left: 8px;
					vertical-align: -1px;
				}
			}

			&.user-email-btn {
				margin-left: 8px;
			}
		}

		.user-edit {
			display: inline-block;
		}

		/deep/ .el-select,
		/deep/ .el-cascader {
			.el-input__inner {
				border-radius: 10px;
			}

			.is-focus .el-input__inner {
				border: 1px solid #01aded;
			}

			.el-input .el-select__caret {
				font-size: 12px;
			}
		}

		.edit-submit-btn {
			margin-left: 16px;

			&.el-button--primary {
				border: 1px solid #01aded;
				background-color: #01aded;

				&:hover {
					background-color: #4ec5f1;

					border: 1px solid #4ec5f1;
				}
			}
		}

		.edit-cancel-btn {
			margin-left: 10px;
			border: 1px solid #9f9f9f;
			color: #9f9f9f;

			&:hover {
				border: 1px solid #9f9f9f;
				background-color: #f5f5f5;
			}
		}

		.edit-submit-btn,
		.edit-cancel-btn {
			border-radius: 4px;
			padding: 0 16px;
			line-height: 30px;
			font-weight: 600;
		}
	}
}

/deep/.el-select-dropdown.el-popper[x-placement^="bottom-start"] {
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.15);
	border: 0;
	margin-top: 4px;
	line-height: 36px;
	overflow: hidden;
	text-align: center;

	.el-select-dropdown__list {
		padding: 10px 0;
	}

	.el-select-dropdown__item {
		&:hover {
			background: #01aded;
			color: #fff;
		}
	}
}
</style>

<style lang="less">
.city-choose {
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.15);
	border: 0;
	margin-top: 4px;
	line-height: 36px;
	overflow: hidden;
	text-align: center;

	.el-cascader-menu__list {
		padding: 10px 0;
	}

	.el-cascader-node {
		&.in-active-path {
			background: #01aded;
			color: #fff;
		}

		&:hover {
			background: #01aded;
			color: #fff;
		}
	}

	.el-cascader-menu {
		border: 0;

		.el-cascader-menu__wrap {
			margin-right: 0 !important;
			padding-right: 5px;

			/*修改滚动条样式*/
			&::-webkit-scrollbar {
				width: 5px;
			}
		}

		.el-cascader-node__postfix {
			display: none;
		}

		.el-icon-check::before {
			display: none;
		}

		.el-cascader-node.is-active {
			background: #01aded;
			color: #fff;
		}
	}
}

// 修改手机号弹窗
@primary-color: #3e6eff;
@text-color: #333;

.editPhoneDialog {
	border-radius: 0;
	padding: 36px 60px 24px;
	box-shadow: 0px 0px 12px 0px rgba(100, 110, 144, 0.15);

	.el-dialog__header {
		padding: 0;
		text-align: center;
	}

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		line-height: 32px;
		color: @text-color;
	}

	.el-dialog__body {
		padding: 35px 0px 1px;
	}

	.el-form-item {
		margin-bottom: 26px;
	}

	.el-input {
		font-size: 16px;

		.el-input__inner {
			border-radius: 10px;
			border: 1px solid #9f9f9f;
			height: 50px;
			line-height: 24px;
			padding: 10px;
			color: #333;

			/* browsers */
			&::placeholder {
				color: #9f9f9f;
				font-size: 16px;
			}
			/* WebKit browsers */
			&::-webkit-input-placeholder {
				color: #9f9f9f;
				font-size: 16px;
			}
			/* Mozilla Firefox 19+ */
			&::-moz-placeholder {
				color: #9f9f9f;
				font-size: 16px;
			}
			/* Internet Explorer 10+ */
			&:-ms-input-placeholder {
				color: #9f9f9f;
				font-size: 16px;
			}
		}

		.el-input__clear {
			font-size: 16px;
			height: 110%;
		}

		&.is-active .el-input__inner,
		.el-input__inner:focus,
		.el-input__inner:hover {
			border-color: @text-color;
			outline: 0;
		}

		&.is-disabled .el-input__inner {
			background-color: #fff;
			border: 1px solid #9f9f9f;
			color: #9f9f9f;
		}
	}

	.code-input {
		width: 182px;
	}

	.send-code {
		margin-left: 20px;
		padding: 10px 28px;
		color: @text-color;
		border: 0;
		border-radius: 10px;
		line-height: 30px;
		background-color: #01aded;
		color: #fff;

		&:active,
		&:hover {
			background-color: #4ec5f1;
		}

		&.is-disabled {
			background-color: #e0e0e0;

			&:hover {
				color: #fff;
			}
		}
	}

	.el-form-item.is-error .el-input__inner,
	.el-form-item.is-error .el-input__inner:focus,
	.el-form-item.is-error .el-textarea__inner,
	.el-form-item.is-error .el-textarea__inner:focus,
	.el-message-box__input input.invalid,
	.el-message-box__input input.invalid:focus {
		border: 1px solid #df0629;
		background-color: #fee6ea;
		box-shadow: 0px 0px 0px 2px rgba(223, 6, 41, 0.2);
	}

	.el-form-item__error {
		color: #df0629;
	}

	.el-dialog__footer {
		padding: 0;

		.el-button {
			width: 100%;
			border-radius: 10px;
			border: 0;
			padding: 10px;
			line-height: 30px;
			font-size: 16px;
			font-weight: 600;
		}

		.el-button.is-disabled {
			background: #e0e0e0;
		}

		.back {
			display: block;
			text-align: center;
			line-height: 30px;
			padding: 10px;
			width: 100%;
			color: #999;
			font-size: 15px;
			font-weight: 600;
			&:hover {
				color: rgba(153, 153, 153, 0.65);
			}
		}
	}
}

.checkEmailDialog {
	padding: 79px 60px 28px;
	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}

	.el-dialog__footer {
		.el-button {
			display: block;
			width: auto;
			margin: 0 auto;
			border-radius: 10px;
			border: 0;
			padding: 5px 32px;
			line-height: 30px;
			font-size: 16px;
			font-weight: 600;
			background-color: #01aded;
		}

		.back {
			display: block;
			text-align: center;
			line-height: 30px;
			padding: 5px;
			width: 100%;
			margin-top: 10px;
		}
	}
}
</style>

<style lang="less" scoped>
/deep/ .edit-cover-wrap {
	height: 100%;
	.crop-image {
		height: 100%;
		background-color: #fff;
		border-radius: 10px;
	}
	// 顶部title
	.crop-image-title {
		line-height: 108px;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		position: relative;

		& > button {
			position: absolute;
			top: 26px;
			left: 30px;
			border-radius: 16px;
			border: 1px solid #01aded;
			color: #01aded;
			line-height: 24px;
			padding: 0 16px;
			font-size: 14px;
			cursor: pointer;

			&:hover {
				background-color: #01aded;
				color: #fff;
			}
		}
	}

	// 裁切主体
	.crop-image-content {
		height: 400px;
		.vue-image-crop-upload {
			background-color: #d9d9d9;
		}

		.vicp-wrap {
			box-shadow: none;
			height: 100%;
			width: 100%;
			background-color: #d9d9d9;
			padding: 0;
			.vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container {
				width: 970px;
				height: 400px;
			}
			.vicp-step2 .vicp-crop .vicp-crop-left .vicp-range {
				margin: 15px auto 30px;
			}
		}
	}

	// 底部按钮
	.crop-image-buttons {
		text-align: center;
		margin-top: 70px;
		.edit-submit-btn {
			&.el-button--primary {
				border: 1px solid #01aded;
				background-color: #01aded;

				&:hover {
					background-color: #4ec5f1;

					border: 1px solid #4ec5f1;
				}
			}
		}

		.edit-cancel-btn {
			margin-left: 11px;
			border: 1px solid #9f9f9f;
			color: #9f9f9f;

			&:hover {
				border: 1px solid #9f9f9f;
				background-color: #f5f5f5;
			}
		}

		.edit-submit-btn,
		.edit-cancel-btn {
			border-radius: 4px;
			padding: 0 16px;
			line-height: 30px;
			font-weight: 600;
		}
	}
}
</style>
