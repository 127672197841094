<template>
  <div>
    <h2>影集</h2>
    <div v-for="item in data" :key="item" class="data-item">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: ["数据A", "数据B", "数据C"],
    };
  },
};
</script>

<style scoped>
.data-item {
  margin-bottom: 10px;
}
</style>