<template>
    <div class="crop-image">
        <div class="crop-image-title">
            <button @click="returnUserInfo(showTag)">返回</button>
            {{ showData.title }}
        </div>
        <div class="crop-image-content">
            <my-upload ref="myUpload" field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail" :width="showData.width" :height="showData.height" :url="showData.url" img-format="png"
                :imgFile="showData.imgFile" :noCircle="showData.noCircle" :noSquare="showData.noSquare"
                :headers="showData.headers"
                :requestKey="showData.requestKey"></my-upload>
        </div>
        <div class="crop-image-buttons">
            <el-button class="edit-submit-btn"  type="primary" @click="uploadImg()">确定</el-button>
            <el-button class="edit-cancel-btn"  @click="returnUserInfo(showTag)">取消</el-button>

        </div>
    </div>
</template>
<script>
    import myUpload from "@/utils/vue-image-crop-upload.vue";
    export default {
        components: {
            'my-upload': myUpload
        },
        props: {
            showTag: {
                type: Number,
                required: true
            },
            showData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                imgDataUrl: '', // the datebase64 url of created image
                imgFile: null
            }
        },
        mounted() {},
        methods: {
            cropSuccess(imgDataUrl, field) {
                // console.log('裁剪成功后的图片数据:', imgDataUrl);
            },
            cropUploadSuccess(jsonData, field) {
                // console.log('上传成功后的响应数据:', jsonData);
                this.back2UserInfo();
            },
            cropUploadFail(status, field) {
                // console.log('上传失败的状态码:', status);
                this.back2UserInfo();
            },
            // 返回到用户信息页面
            returnUserInfo(tag) {
                // console.log('点击返回', tag)
                // TODO 如果没有需要返回操作，则可以删除此方法，直接调用this.back2UserInfo(tag)
                // 从修改封面返回
                if (tag === 2) {

                }
                // 从修改个人头像返回
                if (tag == 3) {

                }
                this.back2UserInfo();
            },
            // 返回用户信息页面
            back2UserInfo() {
				this.$emit('back-2-userInfo', this.showTag);
                this.$emit('update:showTag', 1);
            },
            //上传图片
            uploadImg() {
                console.log('上传图片')
                // 使用 this.$refs 获取子组件实例
                this.$refs.myUpload.prepareUpload();
            }
        },
    }
</script>
<style></style>