var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-page" }, [
    _vm.showTag === 2 || _vm.showTag === 3
      ? _c(
          "div",
          { staticClass: "edit-cover-wrap" },
          [
            _c("crop-image", {
              attrs: { showTag: _vm.showTag, showData: _vm.showData },
              on: {
                "update:showTag": function ($event) {
                  _vm.showTag = $event
                },
                "update:show-tag": function ($event) {
                  _vm.showTag = $event
                },
                "update:showData": function ($event) {
                  _vm.showData = $event
                },
                "update:show-data": function ($event) {
                  _vm.showData = $event
                },
                "back-2-userInfo": _vm.back2UserInfo,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.showTag === 1
      ? _c(
          "div",
          [
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              ref: "imgInput",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.handleImgChange },
            }),
            _c("div", { staticClass: "cover" }, [
              _c("img", {
                staticClass: "cover-photo",
                attrs: {
                  src: _vm.userCover ? _vm.userCover : _vm.defaultCover,
                  alt: "",
                },
              }),
              _c(
                "button",
                { staticClass: "edit-cover", on: { click: _vm.updateCover } },
                [_vm._v("编辑封面图片")]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "userinfo-wrap flex-layout",
                class: { collapsed: _vm.isCollapsed },
              },
              [
                _c(
                  "button",
                  { staticClass: "toggle", on: { click: _vm.toggleCollapse } },
                  [
                    _c("i", {
                      class: _vm.isCollapsed
                        ? "el-icon-arrow-down "
                        : "el-icon-arrow-up",
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isCollapsed ? "查看详细资料" : "收起详细资料"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "user-avatar-wrap",
                    on: { click: _vm.updateAvatar },
                  },
                  [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.userInfo.avatar, alt: "头像" },
                    }),
                  ]
                ),
                _c("div", { staticClass: "userinfo" }, [
                  _c("div", { staticClass: "user-info-top" }, [
                    _c(
                      "div",
                      {
                        style: {
                          "margin-bottom": _vm.showSexIcon ? "18px" : "23px",
                        },
                      },
                      [
                        !_vm.signatureEditing
                          ? _c(
                              "div",
                              {
                                staticClass: "user-name",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.toggleEditBtn("name")
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.toggleEditBtn("name")
                                  },
                                },
                              },
                              [
                                !_vm.nameEditing
                                  ? _c(
                                      "span",
                                      { staticClass: "content name-content" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.truncateName || "昵称")
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.nameEditBtn && !_vm.nameEditing
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "user-edit-btn csp",
                                        on: {
                                          click: function ($event) {
                                            return _vm.startEdit("name")
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm.nameEditing
                                  ? _c(
                                      "div",
                                      { staticClass: "user-edit" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "edit-input",
                                            attrs: {
                                              placeholder: "请填写名称",
                                              maxlength: "32",
                                              disabled: _vm.nameDisabled,
                                            },
                                            model: {
                                              value: _vm.nameValue,
                                              callback: function ($$v) {
                                                _vm.nameValue = $$v
                                              },
                                              expression: "nameValue",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                attrs: { slot: "suffix" },
                                                slot: "suffix",
                                              },
                                              [
                                                _vm._v(
                                                  "本月还可修改" +
                                                    _vm._s(_vm.nameEditTimes) +
                                                    "次"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "edit-submit-btn",
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitEdit("name")
                                              },
                                            },
                                          },
                                          [_vm._v("提交")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "edit-cancel-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelEdit("name")
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        !_vm.nameEditing
                          ? _c(
                              "div",
                              {
                                staticClass: "user-signature",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.toggleEditBtn("signature")
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.toggleEditBtn("signature")
                                  },
                                },
                              },
                              [
                                !_vm.signatureEditing
                                  ? _c("span", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.signatureValue || "未设置个人签名"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.signatureEditBtn && !_vm.signatureEditing
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "user-edit-btn csp",
                                        on: {
                                          click: function ($event) {
                                            return _vm.startEdit("signature")
                                          },
                                        },
                                      },
                                      [_vm._v(" 修改 ")]
                                    )
                                  : _vm._e(),
                                _vm.signatureEditing
                                  ? _c(
                                      "div",
                                      { staticClass: "user-edit" },
                                      [
                                        _c("el-input", {
                                          staticClass: "edit-input",
                                          attrs: {
                                            maxlength: "18",
                                            "show-word-limit": "",
                                            placeholder: "请填写个性签名",
                                          },
                                          model: {
                                            value: _vm.signatureValue,
                                            callback: function ($$v) {
                                              _vm.signatureValue = $$v
                                            },
                                            expression: "signatureValue",
                                          },
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "edit-submit-btn",
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitEdit(
                                                  "signature"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("提交")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "edit-cancel-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelEdit(
                                                  "signature"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.showSexIcon
                      ? _c("div", { staticClass: "user-sex-icon" }, [
                          _vm.genderValue == 1
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/user/man.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.genderValue == 2
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/user/woman.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "user-reward" }, [
                      _c("span", [
                        _c("b", [_vm._v("IP")]),
                        _vm._v(_vm._s(_vm.userIp || "未知")),
                      ]),
                      _c("span", [
                        _c("b", [_vm._v("成就等级")]),
                        _vm._v(_vm._s(_vm.userInfo.level)),
                      ]),
                      _c("span", [
                        _c("b", [_vm._v("积分")]),
                        _vm._v(_vm._s(_vm.userInfo.points || 0)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "user-other user-industry",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("industry")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("industry")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("所在行业"),
                      ]),
                      !_vm.industryEditing
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.industryValue || "未设置")),
                          ])
                        : _vm._e(),
                      _vm.industryEditBtn && !_vm.industryEditing
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn csp",
                              on: {
                                click: function ($event) {
                                  return _vm.startEdit("industry")
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.industryEditing
                        ? _c(
                            "div",
                            { staticClass: "user-edit" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "330px" },
                                  attrs: {
                                    placeholder: "未设置",
                                    "popper-append-to-body": false,
                                  },
                                  model: {
                                    value: _vm.industryValue,
                                    callback: function ($$v) {
                                      _vm.industryValue = $$v
                                    },
                                    expression: "industryValue",
                                  },
                                },
                                _vm._l(_vm.industryOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-submit-btn",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitEdit("industry")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-cancel-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEdit("industry")
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "user-other user-city",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("city")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("city")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("现居地"),
                      ]),
                      !_vm.cityEditing
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.cityValue || "未设置")),
                          ])
                        : _vm._e(),
                      _vm.cityEditBtn && !_vm.cityEditing
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn csp",
                              on: {
                                click: function ($event) {
                                  return _vm.startEdit("city")
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.cityEditing
                        ? _c(
                            "div",
                            { staticClass: "user-edit" },
                            [
                              _c("el-cascader", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  options: _vm.cityOptions,
                                  popperClass: "city-choose",
                                  "append-to-body": false,
                                  props: { value: "label", emitPath: false },
                                },
                                on: { change: _vm.cityChange },
                                model: {
                                  value: _vm.cityValue,
                                  callback: function ($$v) {
                                    _vm.cityValue = $$v
                                  },
                                  expression: "cityValue",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-submit-btn",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitEdit("city")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-cancel-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEdit("city")
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "user-other user-sex",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("gender")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("gender")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("性别"),
                      ]),
                      !_vm.genderEditing
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.genderMap[_vm.genderValue] || "未设置")
                            ),
                          ])
                        : _vm._e(),
                      _vm.genderEditBtn && !_vm.genderEditing
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn csp",
                              on: {
                                click: function ($event) {
                                  return _vm.startEdit("gender")
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.genderEditing
                        ? _c(
                            "div",
                            { staticClass: "user-edit" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    placeholder: "未设置",
                                    "popper-append-to-body": false,
                                  },
                                  model: {
                                    value: _vm.genderValue,
                                    callback: function ($$v) {
                                      _vm.genderValue = $$v
                                    },
                                    expression: "genderValue",
                                  },
                                },
                                _vm._l(_vm.genderOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-submit-btn",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitEdit("gender")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-cancel-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEdit("gender")
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "user-other user-phone",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("phone")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("phone")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("手机号"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.formattedPhoneNumber))]),
                      _c("i", { staticClass: "el-icon-success" }),
                      _vm.phoneEditBtn
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn csp",
                              on: {
                                click: function ($event) {
                                  _vm.visibleEditPhone = true
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "user-other user-email",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("email")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("email")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("邮箱"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.emailValue))]),
                      _vm.emailVerified
                        ? _c("i", { staticClass: "el-icon-success" })
                        : _vm._e(),
                      _vm.emailEditBtn && !_vm.emailVerified
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn user-check-btn csp",
                              on: {
                                click: function ($event) {
                                  return _vm.emailVerify()
                                },
                              },
                            },
                            [_vm._v("验证")]
                          )
                        : _vm._e(),
                      _vm.emailEditBtn
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn user-email-btn csp",
                              on: { click: _vm.editEmail },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "user-other",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleEditBtn("introduction")
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleEditBtn("introduction")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "user-other-title" }, [
                        _vm._v("个人简介"),
                      ]),
                      !_vm.introductionEditing
                        ? _c(
                            "span",
                            { staticClass: "content introduce-content" },
                            [_vm._v(_vm._s(_vm.introductionValue || "未填写"))]
                          )
                        : _vm._e(),
                      _vm.introductionEditBtn && !_vm.introductionEditing
                        ? _c(
                            "button",
                            {
                              staticClass: "user-edit-btn csp",
                              on: {
                                click: function ($event) {
                                  return _vm.startEdit("introduction")
                                },
                              },
                            },
                            [_vm._v(" 修改 ")]
                          )
                        : _vm._e(),
                      _vm.introductionEditing
                        ? _c(
                            "div",
                            { staticClass: "user-edit introduction" },
                            [
                              _c("el-input", {
                                staticClass: "edit-textarea",
                                attrs: {
                                  type: "textarea",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  resize: "none",
                                  placeholder: "填写个人简介",
                                },
                                model: {
                                  value: _vm.introductionValue,
                                  callback: function ($$v) {
                                    _vm.introductionValue = $$v
                                  },
                                  expression: "introductionValue",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-submit-btn",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitEdit("introduction")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-cancel-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEdit("introduction")
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
            _c("collection"),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "修改手机号码",
                  visible: _vm.visibleEditPhone,
                  "append-to-body": true,
                  "show-close": true,
                  width: "450px",
                  "destroy-on-close": true,
                  "close-on-click-modal": false,
                  "custom-class": "editPhoneDialog",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visibleEditPhone = $event
                  },
                  opened: _vm.openDialog,
                  close: _vm.closePhoneDialog,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "editPhoneForm",
                    attrs: {
                      model: _vm.editPhoneForm,
                      rules: _vm.phoneFormRules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "phone" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formattedPhoneNumber,
                            callback: function ($$v) {
                              _vm.formattedPhoneNumber = $$v
                            },
                            expression: "formattedPhoneNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "code" } },
                      [
                        _c("el-input", {
                          staticClass: "code-input",
                          attrs: { id: "code", placeholder: "请输入验证码" },
                          model: {
                            value: _vm.editPhoneForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.editPhoneForm, "code", $$v)
                            },
                            expression: "editPhoneForm.code",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "send-code csp active",
                            attrs: {
                              id: "CaptchaId",
                              disabled: _vm.sendPhoneCodeBtn,
                            },
                            on: { click: _vm.sendPhoneCode },
                          },
                          [_vm._v(" " + _vm._s(_vm.phoneCodeText) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "newPhone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "新手机号码" },
                          model: {
                            value: _vm.editPhoneForm.newPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.editPhoneForm, "newPhone", $$v)
                            },
                            expression: "editPhoneForm.newPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "newCode" } },
                      [
                        _c("el-input", {
                          staticClass: "code-input",
                          attrs: { id: "newCode", placeholder: "请输入验证码" },
                          model: {
                            value: _vm.editPhoneForm.newCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.editPhoneForm, "newCode", $$v)
                            },
                            expression: "editPhoneForm.newCode",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "send-code csp",
                            attrs: {
                              id: "CaptchaId",
                              disabled: _vm.sendPhoneNewCodeBtn,
                            },
                            on: { click: _vm.sendNewPhoneCode },
                          },
                          [_vm._v(" " + _vm._s(_vm.newPhoneCodeText) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.phoneSubmitBtn,
                        },
                        on: { click: _vm.phoneSubmit },
                      },
                      [_vm._v("确定")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "back csp",
                        on: { click: _vm.closePhoneDialog },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "请登录邮箱进行验证",
                  visible: _vm.visibleCheckEmail,
                  "append-to-body": true,
                  "show-close": true,
                  width: "450px",
                  "destroy-on-close": true,
                  "close-on-click-modal": false,
                  "custom-class": "editPhoneDialog checkEmailDialog",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visibleCheckEmail = $event
                  },
                  opened: _vm.openDialog,
                  close: _vm.closeDialog,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.isLoginLoading },
                        on: { click: _vm.finishVerify },
                      },
                      [_vm._v("我已验证")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "back csp",
                        on: {
                          click: function ($event) {
                            _vm.visibleCheckEmail = false
                          },
                        },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "修改邮箱地址",
                  visible: _vm.visibleEditEmail,
                  "append-to-body": true,
                  "show-close": true,
                  width: "450px",
                  "destroy-on-close": true,
                  "close-on-click-modal": false,
                  "custom-class": "editPhoneDialog",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visibleEditEmail = $event
                  },
                  opened: _vm.openDialog,
                  close: _vm.closeEmailDialog,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "editEmailForm",
                    attrs: {
                      model: _vm.editEmailForm,
                      rules: _vm.emailFormRules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "phone" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.editEmailForm.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.editEmailForm, "phone", $$v)
                            },
                            expression: "editEmailForm.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "code" } },
                      [
                        _c("el-input", {
                          staticClass: "code-input",
                          attrs: { id: "code", placeholder: "请输入验证码" },
                          model: {
                            value: _vm.editEmailForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.editEmailForm, "code", $$v)
                            },
                            expression: "editEmailForm.code",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "send-code csp active",
                            attrs: {
                              id: "CaptchaId",
                              disabled: _vm.sendCodeBtn,
                            },
                            on: { click: _vm.sendCode },
                          },
                          [_vm._v(" " + _vm._s(_vm.codeText) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "newEmail" } },
                      [
                        _c("el-input", {
                          attrs: { id: "newEmail", placeholder: "新邮箱地址" },
                          model: {
                            value: _vm.editEmailForm.newEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.editEmailForm, "newEmail", $$v)
                            },
                            expression: "editEmailForm.newEmail",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.emailSubmitBtn,
                          loading: _vm.isLoginLoading,
                        },
                        on: { click: _vm.submitEmail },
                      },
                      [_vm._v("确定")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "back csp",
                        on: { click: _vm.closeEmailDialog },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }