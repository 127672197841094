var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collection" }, [
    _c(
      "div",
      { staticClass: "nav-bar" },
      [
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "nav-item-container",
              on: {
                click: function ($event) {
                  return _vm.selectItem(index)
                },
              },
            },
            [_c("div", { staticClass: "nav-item" }, [_vm._v(_vm._s(item))])]
          )
        }),
        _c("div", {
          staticClass: "underline",
          style: { transform: `translateX(${_vm.currentIndex * 100}%)` },
        }),
        _c(
          "el-select",
          {
            attrs: {
              placeholder: "请选择",
              "popper-class": "type-select",
              "popper-append-to-body": false,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          _vm._l(_vm.options, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(_vm.currentComponent, {
          tag: "component",
          attrs: { type: _vm.value },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }