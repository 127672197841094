<template>
	<div class="prespective">
		<ul :class="!type ? 'list' : 'grid-list'" v-loading="listLoading">
			<li v-for="item in camerasList" :key="item.camera_id" class="item csp" @click="toView(item)">
				<img :src="item.latest_image" alt="缩略图" class="thumbnail" />
				<div class="info">
					<p class="name">{{ item.camera_name }}</p>
					<p class="region">{{ item.location.replace("｜", " ") }}</p>
				</div>
			</li>
		</ul>
		<pagination
			v-if="pages.total"
			class="pages"
			:total="pages.total"
			:limit="pages.limit"
			:page.sync="pages.page"
			:layout="`prev, pager, next, jumper`"
			@pagination="handlePage"></pagination>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: Number,
		},
	},
	data() {
		return {
			pages: {
				total: 0,
				page: 1,
				limit: 4,
			},
			listLoading:false,
			camerasList: [],
		};
	},
	computed: {
		viewMode() {
			return this.type === 0 ? "列表" : "卡片";
		},
	},
	mounted() {
		this.getCamerasList();
	},
	methods: {
		handlePage(params) {
			console.log(params);
		},
		getCamerasList() {
			this.listLoading = true;
			Api.Cameras.getCamerasList()
				.then((resData) => {
					// console.log(resData);
					this.camerasList = resData.data;
					this.pages.total = resData.data.length;
					this.listLoading = false;
				})
				.catch((error) => {
					console.log("getCamerasList-----Error", error);
				});
		},
		// 去视角详情
		toView(item) {
			this.$router.push({
				name: "view",
				params: {
					id: item.camera_id,
				},
			});
		},
	},
};
</script>
<style lang="less" scoped>
.prespective {
	.list {
		min-height: 200px;
		.item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e2e5ed;
			padding-left: 31px;
			color: #000;
			&:last-child {
				border-bottom: 1px solid transparent;
			}

			&:hover {
				background: #f1faff;

				&:last-child {
					border-bottom: 1px solid #e2e5ed;
				}
			}
		}

		.thumbnail {
			width: 164px;
			height: 93px;
			margin: 16px 31px 16px 0;
		}

		.name {
			display: inline-block;
			min-width: 330px;
		}

		.region {
			display: inline-block;
			margin-left: 10px;
		}
	}

	.grid-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		padding: 45px 60px 5px;

		.item {
			width: 100%;

			&:hover .thumbnail {
				filter: brightness(85%);
			}
		}

		.thumbnail {
			display: block;
			width: 100%;
			height: 153px;
		}

		.info {
			border-radius: 0px 0px 4px 4px;
			background-color: #f2f2f2;
			padding: 7px 8px 7px 13px;
		}

		.name {
			font-weight: bold;
			color: #000;
			font-size: 15px;
		}

		.region {
			color: #666;
			text-align: right;
			font-size: 12px;
			line-height: 24px;
		}
	}

	.pages {
		text-align: right;
		margin-right: 65px;
	}
}
</style>
