<template>
	<div class="collection">
		<div class="nav-bar">
			<div class="nav-item-container" v-for="(item, index) in items" :key="index" @click="selectItem(index)">
				<div class="nav-item">{{ item }}</div>
			</div>
			<div class="underline" :style="{ transform: `translateX(${currentIndex * 100}%)` }"></div>
			<el-select v-model="value" placeholder="请选择" popper-class="type-select" :popper-append-to-body="false">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
			</el-select>
		</div>
		<div class="content">
			<component :is="currentComponent"  :type="value"></component>
		</div>
	</div>
</template>

<script>
import Perspective from "./components/prespective.vue";
import Album from "./components/album.vue";
import Follow from "./components/follow.vue";
import Points from "./components/points.vue";
import Achievements from "./components/achievements.vue";
export default {
	components: {
		Perspective,
		Album,
		Follow,
		Points,
		Achievements,
	},
	data() {
		return {
			items: ["视角", "影集", "关注", "积分", "成就"],
			value: 0,
			options: [
				{ value: 0, label: "列表" },
				{ value: 1, label: "卡片" },
			],
			currentIndex: 0,
		};
	},
	computed: {
		currentComponent() {
			switch (this.items[this.currentIndex]) {
				case "视角":
					return "Perspective";
				case "影集":
					return "Album";
				case "关注":
					return "Follow";
				case "积分":
					return "Points";
				case "成就":
					return "Achievements";
				default:
					return "Perspective";
			}
		},
	},
	methods: {
		selectItem(index) {
			this.currentIndex = index;
		},
	},
};
</script>

<style lang="less" scoped>
.collection {
	border-radius: 10px;
	background-color: #ffff;
	overflow: hidden;
	min-height: 300px;
	.nav-bar {
		display: flex;
		align-items: center;
		padding-right: 40px;
		position: relative;
		border-bottom: 1px solid #e2e5ed;
	}

	.nav-item-container {
		position: relative;
		text-align: center;
		cursor: pointer;
		transition: color 0.3s;
		line-height: 48px;
		padding: 0 30px;
	}

	.nav-item-container:hover {
		color: #01aded;
	}

	.nav-item {
		position: relative;
	}

	.underline {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 88px; /* 调整宽度以适应导航项 */
		height: 3px;
		background-color: #01aded;
		transition: transform 0.3s ease-in-out;
	}

	.content {
		// padding: 10px;
		background-color: #fff;
	}

	.data-item {
		margin-bottom: 10px;
	}

	/deep/.el-select {
		position: absolute;
		right: 40px;
		width: 80px;

		&:hover .el-input__inner {
			background-color: #e7e7e7;
		}

		.el-input__inner {
			border: none;
			background-color: #efefef;
			color: #333;
			font-size: 14px;
			padding: 0 10px;
			height: 24px;
			border-radius: 23px;
		}

		.el-select__caret {
			color: #333;
			font-size: 12px;
			line-height: 24px;
			border-radius: 23px;
		}

		.type-select {
			width: 80px;
			min-width: auto !important;
			background-color: #f7f7f7;

			.el-select-dropdown__item.selected {
				background-color: #01aded;
				color: #fff;
			}
		}
	}
}
</style>
