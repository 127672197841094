var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", [_vm._v("关注")]),
      _vm._l(_vm.data, function (item) {
        return _c("div", { key: item, staticClass: "data-item" }, [
          _vm._v(" " + _vm._s(item) + " "),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }