var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.modelShow,
          expression: "modelShow",
        },
      ],
      staticClass: "vue-image-crop-upload",
    },
    [
      _c("div", { staticClass: "vicp-wrap" }, [
        _c("div", { staticClass: "vicp-close", on: { click: _vm.off } }, [
          _c("i", { staticClass: "vicp-icon4" }),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            staticClass: "vicp-step1",
          },
          [
            _c(
              "div",
              {
                staticClass: "vicp-drop-area",
                on: {
                  dragleave: _vm.preventDefault,
                  dragover: _vm.preventDefault,
                  dragenter: _vm.preventDefault,
                  click: _vm.handleClick,
                  drop: _vm.handleChange,
                },
              },
              [
                _c(
                  "i",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading != 1,
                        expression: "loading != 1",
                      },
                    ],
                    staticClass: "vicp-icon1",
                  },
                  [
                    _c("i", { staticClass: "vicp-icon1-arrow" }),
                    _c("i", { staticClass: "vicp-icon1-body" }),
                    _c("i", { staticClass: "vicp-icon1-bottom" }),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading !== 1,
                        expression: "loading !== 1",
                      },
                    ],
                    staticClass: "vicp-hint",
                  },
                  [_vm._v(_vm._s(_vm.lang.hint))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSupported,
                        expression: "!isSupported",
                      },
                    ],
                    staticClass: "vicp-no-supported-hint",
                  },
                  [_vm._v(_vm._s(_vm.lang.noSupported))]
                ),
                _vm.step == 1
                  ? _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      ref: "fileinput",
                      attrs: { type: "file", accept: "image/*" },
                      on: { change: _vm.handleChange },
                    })
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasError,
                    expression: "hasError",
                  },
                ],
                staticClass: "vicp-error",
              },
              [
                _c("i", { staticClass: "vicp-icon2" }),
                _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
              ]
            ),
            _c("div", { staticClass: "vicp-operate" }, [
              _c("a", { on: { click: _vm.off, mousedown: _vm.ripple } }, [
                _vm._v(_vm._s(_vm.lang.btn.off)),
              ]),
            ]),
          ]
        ),
        _vm.step == 2
          ? _c("div", { staticClass: "vicp-step2" }, [
              _c("div", { staticClass: "vicp-crop" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "vicp-crop-left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "vicp-img-container",
                        on: {
                          wheel: function ($event) {
                            $event.preventDefault()
                            return _vm.handleMouseWheel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("img", {
                          ref: "img",
                          staticClass: "vicp-img",
                          style: _vm.sourceImgStyle,
                          attrs: { src: _vm.sourceImgUrl, draggable: "false" },
                          on: {
                            drag: _vm.preventDefault,
                            dragstart: _vm.preventDefault,
                            dragend: _vm.preventDefault,
                            dragleave: _vm.preventDefault,
                            dragover: _vm.preventDefault,
                            dragenter: _vm.preventDefault,
                            drop: _vm.preventDefault,
                            touchstart: _vm.imgStartMove,
                            touchmove: _vm.imgMove,
                            touchend: _vm.createImg,
                            touchcancel: _vm.createImg,
                            mousedown: _vm.imgStartMove,
                            mousemove: _vm.imgMove,
                            mouseup: _vm.createImg,
                            mouseout: _vm.createImg,
                          },
                        }),
                        _c("div", {
                          staticClass: "vicp-img-shade vicp-img-shade-1",
                          style: _vm.sourceImgShadeStyle,
                        }),
                        _c("div", {
                          staticClass: "vicp-img-shade vicp-img-shade-2",
                          style: _vm.sourceImgShadeStyle,
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "vicp-range" }, [
                      _c("input", {
                        attrs: {
                          type: "range",
                          step: "1",
                          min: "0",
                          max: "100",
                        },
                        domProps: { value: _vm.scale.range },
                        on: { mousemove: _vm.zoomChange },
                      }),
                      _c("i", {
                        staticClass: "vicp-icon5",
                        on: {
                          mousedown: _vm.startZoomSub,
                          mouseout: _vm.endZoomSub,
                          mouseup: _vm.endZoomSub,
                        },
                      }),
                      _c("i", {
                        staticClass: "vicp-icon6",
                        on: {
                          mousedown: _vm.startZoomAdd,
                          mouseout: _vm.endZoomAdd,
                          mouseup: _vm.endZoomAdd,
                        },
                      }),
                    ]),
                    !_vm.noRotate
                      ? _c("div", { staticClass: "vicp-rotate" }, [
                          _c("i", { on: { click: _vm.rotateImg } }, [
                            _vm._v("↻"),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "vicp-crop-right",
                  },
                  [
                    _c("div", { staticClass: "vicp-preview" }, [
                      !_vm.noSquare
                        ? _c("div", { staticClass: "vicp-preview-item" }, [
                            _c("img", {
                              style: _vm.previewStyle,
                              attrs: { src: _vm.createImgUrl },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.lang.preview))]),
                          ])
                        : _vm._e(),
                      !_vm.noCircle
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vicp-preview-item vicp-preview-item-circle",
                            },
                            [
                              _c("img", {
                                style: _vm.previewStyle,
                                attrs: { src: _vm.createImgUrl },
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.lang.preview))]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "vicp-operate" }),
            ])
          : _vm._e(),
        false
          ? _c("div", { staticClass: "vicp-step3" }, [
              _c("div", { staticClass: "vicp-upload" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading === 1,
                        expression: "loading === 1",
                      },
                    ],
                    staticClass: "vicp-loading",
                  },
                  [_vm._v(_vm._s(_vm.lang.loading))]
                ),
                _c("div", { staticClass: "vicp-progress-wrap" }, [
                  _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading === 1,
                        expression: "loading === 1",
                      },
                    ],
                    staticClass: "vicp-progress",
                    style: _vm.progressStyle,
                  }),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasError,
                        expression: "hasError",
                      },
                    ],
                    staticClass: "vicp-error",
                  },
                  [
                    _c("i", { staticClass: "vicp-icon2" }),
                    _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading === 2,
                        expression: "loading === 2",
                      },
                    ],
                    staticClass: "vicp-success",
                  },
                  [
                    _c("i", { staticClass: "vicp-icon3" }),
                    _vm._v(" " + _vm._s(_vm.lang.success) + " "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "vicp-operate" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.setStep(2)
                      },
                      mousedown: _vm.ripple,
                    },
                  },
                  [_vm._v(_vm._s(_vm.lang.btn.back))]
                ),
                _c("a", { on: { click: _vm.off, mousedown: _vm.ripple } }, [
                  _vm._v(_vm._s(_vm.lang.btn.close)),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("canvas", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          ref: "canvas",
          attrs: { width: _vm.width, height: _vm.height },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }