var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "crop-image" }, [
    _c("div", { staticClass: "crop-image-title" }, [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.returnUserInfo(_vm.showTag)
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" " + _vm._s(_vm.showData.title) + " "),
    ]),
    _c(
      "div",
      { staticClass: "crop-image-content" },
      [
        _c("my-upload", {
          ref: "myUpload",
          attrs: {
            field: "img",
            width: _vm.showData.width,
            height: _vm.showData.height,
            url: _vm.showData.url,
            "img-format": "png",
            imgFile: _vm.showData.imgFile,
            noCircle: _vm.showData.noCircle,
            noSquare: _vm.showData.noSquare,
            headers: _vm.showData.headers,
            requestKey: _vm.showData.requestKey,
          },
          on: {
            "crop-success": _vm.cropSuccess,
            "crop-upload-success": _vm.cropUploadSuccess,
            "crop-upload-fail": _vm.cropUploadFail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "crop-image-buttons" },
      [
        _c(
          "el-button",
          {
            staticClass: "edit-submit-btn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.uploadImg()
              },
            },
          },
          [_vm._v("确定")]
        ),
        _c(
          "el-button",
          {
            staticClass: "edit-cancel-btn",
            on: {
              click: function ($event) {
                return _vm.returnUserInfo(_vm.showTag)
              },
            },
          },
          [_vm._v("取消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }