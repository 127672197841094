var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prespective" },
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          class: !_vm.type ? "list" : "grid-list",
        },
        _vm._l(_vm.camerasList, function (item) {
          return _c(
            "li",
            {
              key: item.camera_id,
              staticClass: "item csp",
              on: {
                click: function ($event) {
                  return _vm.toView(item)
                },
              },
            },
            [
              _c("img", {
                staticClass: "thumbnail",
                attrs: { src: item.latest_image, alt: "缩略图" },
              }),
              _c("div", { staticClass: "info" }, [
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.camera_name)),
                ]),
                _c("p", { staticClass: "region" }, [
                  _vm._v(_vm._s(item.location.replace("｜", " "))),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.pages.total
        ? _c("pagination", {
            staticClass: "pages",
            attrs: {
              total: _vm.pages.total,
              limit: _vm.pages.limit,
              page: _vm.pages.page,
              layout: `prev, pager, next, jumper`,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pages, "page", $event)
              },
              pagination: _vm.handlePage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }