import { render, staticRenderFns } from "./myPage.vue?vue&type=template&id=5c1cd982&scoped=true"
import script from "./myPage.vue?vue&type=script&lang=js"
export * from "./myPage.vue?vue&type=script&lang=js"
import style0 from "./myPage.vue?vue&type=style&index=0&id=5c1cd982&lang=less&scoped=true"
import style1 from "./myPage.vue?vue&type=style&index=1&id=5c1cd982&lang=less"
import style2 from "./myPage.vue?vue&type=style&index=2&id=5c1cd982&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1cd982",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c1cd982')) {
      api.createRecord('5c1cd982', component.options)
    } else {
      api.reload('5c1cd982', component.options)
    }
    module.hot.accept("./myPage.vue?vue&type=template&id=5c1cd982&scoped=true", function () {
      api.rerender('5c1cd982', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/community/myPage/myPage.vue"
export default component.exports